<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-tabs>
            <b-tab active title="English">
              <b-card-text>
                <validation-observer ref="teamMemberForm">
                  <b-form>
                    <b-row>
                      <b-col class="align-self-center">
                        <b-form-group label="Team Member Name">
                          <validation-provider
                            #default="{ errors }"
                            name="Name"
                            rules="required"
                          >
                            <b-form-input
                              v-model="team.name"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Team Member Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Designation">
                          <b-form-input
                            v-model="team.designation"
                            placeholder="Team Member Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Quote">
                          <b-form-textarea
                            v-model="team.quote"
                            placeholder="Quote"
                            rows="3"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Description">
                          <b-form-textarea
                            v-model="team.description"
                            placeholder="Description"
                            rows="3"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="LinkedIn URL">
                          <b-form-input
                            v-model="team.linkedInUrl"
                            placeholder="LinkedIn URL"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">  
                          <b-row>
                            <b-col
                              cols="6"
                              class="align-self-center text-center"
                            >
                              <span class="mb-1 d-block"
                                >Select Thumbnail Image</span
                              >
                              <b-img
                                v-if="team.thumbnailImg"
                                v-bind="thumbProps"
                                :src="team.thumbnailImg"
                              />
                              <ComponentMediaSelector
                                class="mt-1"
                                :params="{lang: null}"
                                buttonText="Select Thumbnail Image"
                                :isMultiple="false"
                                @mediaSelected="handleThumbMediaSelect"
                              />
                            </b-col>
                            <b-col
                              cols="6"
                              class="align-self-center text-center mt-1"
                            >
                              <span class="mb-1 d-block"
                                >Select Main Image</span
                              >
                              <b-img
                                v-if="team.mainImg"
                                v-bind="mainProps"
                                rounded
                                :src="team.mainImg"
                              />
                              <ComponentMediaSelector
                                class="mt-1"
                                :params="{lang: null}"
                                buttonText="Select Main Image"
                                :isMultiple="false"
                                @mediaSelected="handleMainMediaSelect"
                              />
                            </b-col>
                          </b-row>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-text>
            </b-tab>
            <b-tab title="Hindi">
              <b-card-text>
                <validation-observer ref="teamMemberForm">
                  <b-form>
                    <b-row>
                      <b-col md="6" class="align-self-center">
                        <b-form-group label="Team Member Name">
                          <b-form-input
                            v-model="team.translations.hi.name"
                            placeholder="Team Member Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Designation">
                          <b-form-input
                            v-model="team.translations.hi.designation"
                            placeholder="Team Member Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Quote">
                          <b-form-textarea
                            v-model="team.translations.hi.quote"
                            placeholder="Quote"
                            rows="3"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Description">
                          <b-form-textarea
                            v-model="team.translations.hi.description"
                            placeholder="Description"
                            rows="3"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                        <b-col cols="6">
                        <b-form-group label="LinkedIn URL">
                          <b-form-input
                            v-model="team.translations.hi.linkedInUrl"
                            placeholder="LinkedIn URL"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">  
                          <b-row>
                            <b-col
                              cols="6"
                              class="align-self-center text-center"
                            >
                              <span class="mb-1 d-block"
                                >Select Thumbnail Image</span
                              >
                              <b-img
                                v-if="team.translations.hi.thumbnailImg"
                                v-bind="thumbProps"
                                :src="team.translations.hi.thumbnailImg"
                              />
                              <ComponentMediaSelector
                                class="mt-1"
                                :params="{lang: 'hi'}"
                                buttonText="Select Thumbnail Image"
                                :isMultiple="false"
                                @mediaSelected="handleThumbMediaSelect"
                              />
                            </b-col>
                            <b-col
                              cols="6"
                              class="align-self-center text-center mt-1"
                            >
                              <span class="mb-1 d-block"
                                >Select Main Image</span
                              >
                              <b-img
                                v-if="team.translations.hi.mainImg"
                                v-bind="mainProps"
                                rounded
                                :src="team.translations.hi.mainImg"
                              />
                              <ComponentMediaSelector
                                class="mt-1"
                                :params="{lang: 'hi'}"
                                buttonText="Select Main Image"
                                :isMultiple="false"
                                @mediaSelected="handleMainMediaSelect"
                              />
                            </b-col>
                          </b-row>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-text>
            </b-tab>
            <b-tab title="Marathi">
              <b-card-text>
                <validation-observer ref="teamMemberForm">
                  <b-form>
                    <b-row>
                      <b-col md="6" class="align-self-center">
                        <b-form-group label="Team Member Name">
                          <b-form-input
                            v-model="team.translations.mr.name"
                            placeholder="Team Member Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Designation">
                          <b-form-input
                            v-model="team.translations.mr.designation"
                            placeholder="Team Member Name"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Quote">
                          <b-form-textarea
                            v-model="team.translations.mr.quote"
                            placeholder="Quote"
                            rows="3"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Description">
                          <b-form-textarea
                            v-model="team.translations.mr.description"
                            placeholder="Description"
                            rows="3"
                          >
                          </b-form-textarea>
                        </b-form-group>
                      </b-col>
                        <b-col cols="6">
                        <b-form-group label="LinkedIn URL">
                          <b-form-input
                            v-model="team.translations.mr.linkedInUrl"
                            placeholder="LinkedIn URL"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">  
                          <b-row>
                            <b-col
                              cols="6"
                              class="align-self-center text-center"
                            >
                              <span class="mb-1 d-block"
                                >Select Thumbnail Image</span
                              >
                              <b-img
                                v-if="team.translations.mr.thumbnailImg"
                                v-bind="thumbProps"
                                :src="team.translations.mr.thumbnailImg"
                              />
                              <ComponentMediaSelector
                                class="mt-1"
                                :params="{lang: 'mr'}"
                                buttonText="Select Thumbnail Image"
                                :isMultiple="false"
                                @mediaSelected="handleThumbMediaSelect"
                              />
                            </b-col>
                            <b-col
                              cols="6"
                              class="align-self-center text-center mt-1"
                            >
                              <span class="mb-1 d-block"
                                >Select Main Image</span
                              >
                              <b-img
                                v-if="team.translations.mr.mainImg"
                                v-bind="mainProps"

                                rounded
                                :src="team.translations.mr.mainImg"
                              />
                              <ComponentMediaSelector
                                class="mt-1"
                                :params="{ lang: 'mr' }"
                                buttonText="Select Main Image"
                                :isMultiple="false"
                                @mediaSelected="handleMainMediaSelect"
                              />
                            </b-col>
                          </b-row>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>

        <b-card>
          <b-button block @click="createTeam" variant="primary"
            >Create Team</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BImg,
  BFormTextarea,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ComponentMediaSelector,
    BImg,
    BFormTextarea,
  },
  data() {
    return {
      required,
      thumbProps: {
        width: 150,
        height: 150,
      },
      mainProps: {
        width: 170,
        height: 213,
      },
      team: {
        name: "",
        designation: "",
        thumbnailImg: "",
        mainImg: "",
        quote: "",
        description: "",
        linkedInUrl: "",
        translations: {
          hi: {
            name: "",
        designation: "",
        thumbnailImg: "",
        mainImg: "",
        quote: "",
        description: "",
        linkedInUrl: "",
          },
          mr: {
            name: "",
        designation: "",
        thumbnailImg: "",
        mainImg: "",
        quote: "",
        description: "",
        linkedInUrl: "",
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleThumbMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.team.translations[mediaObject.params.lang].thumbnailImg = mediaObject.media.src;
        } else {
        this.team.thumbnailImg = mediaObject.media.src;
        }
      }
    },
    handleMainMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.team.translations[mediaObject.params.lang].mainImg = mediaObject.media.src;
        } else {
        this.team.mainImg = mediaObject.media.src;
        }
      }
    },
    createTeam() {
      this.$refs.teamMemberForm.validate().then((success) => {
        if (success) {
          axios
            .post(`${process.env.VUE_APP_SERVER_URL}/org/team/create`, this.team, {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            })
            .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.name} has been created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast("danger", "Error", "Error while creating team!");
            });
        }
      });
    },
  },
};
</script>
 